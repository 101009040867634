// 基础数据
// 基础组件
export const basicsIcon = [
    {
      name: 'navigation',
      icon: 'anniuzu2',
      text: '底部导航栏',
      cursor: false,
      maximum: 1,
      num: 0,
      place: ['navigation']
    },
    {
        name: 'immersive',
        icon: 'anniuzu2',
        text: '沉浸式轮播图',
        cursor: false,
        maximum: 1,
        num: 0,
        place: ['indexPage', 'pagesCustom']
    },
    {
        name: 'switching',
        icon: 'anniuzu2',
        text: '门店切换',
        cursor: false,
        maximum: 5,
        num: 0,
        place: ['indexPage', 'pagesCustom']
    },
    // {
    //     name: 'rubikcube',
    //     icon: 'fenlei',
    //     text: '魔方',
    //     cursor: false,
    //     maximum: 50,
    //     num: 0,
    //     place: ['indexPage', 'pagesCustom', 'categoryPage', 'personalCenter', 'goodsDetail']
    // },
    {
        name: 'wenben',
        icon: 'font-size',
        text: '标题文本',
        cursor: false,
        maximum: 50,
        num: 0,
        place: ['indexPage', 'pagesCustom', 'categoryPage', 'personalCenter', 'goodsDetail']
    },
    {
        name: 'biaotilan',
        icon: 'biaotilan2-9een0gk5',
        text: '标题栏',
        cursor: false,
        maximum: 50,
        num: 0,
        place: ['indexPage', 'pagesCustom', 'categoryPage', 'personalCenter', 'goodsDetail']
    },
    {
        name: 'goods',
        icon: 'commodity',
        text: '商品',
        cursor: false,
        maximum: 100,
        num: 0,
        place: ['indexPage', 'pagesCustom', 'goodsDetail']
    },
    {
        name: 'mofang',
        icon: 'fenlei',
        text: '魔方',
        cursor: false,
        maximum: 50,
        num: 0,
        place: []
  },
  {
    name: 'seniorCube',
    icon: 'fenlei',
    text: '高级魔方',
    cursor: false,
    maximum: 50,
    num: 0,
    place: ['indexPage', 'pagesCustom', 'categoryPage', 'personalCenter', 'goodsDetail']
  },
    {
        name: 'guanggao',
        icon: 'zhaopian',
        text: '广告图片',
        cursor: false,
        maximum: 500,
        num: 0,
        place: ['indexPage', 'pagesCustom', 'categoryPage', 'personalCenter', 'goodsDetail']
    },
    {
        name: 'imageNav',
        icon: 'tuwendaohang2',
        text: '图文导航',
        cursor: false,
        maximum: 10,
        num: 0,
        place: ['indexPage', 'pagesCustom', 'categoryPage', 'personalCenter', 'goodsDetail']
    },
    {
        name: 'sousuo',
        icon: 'sousuo',
        text: '商品搜索',
        cursor: false,
        maximum: 1,
        num: 0,
        place: ['indexPage', 'pagesCustom', 'categoryPage', 'goodsDetail']
    },
    {
        name: 'gonggao',
        icon: 'speaker-one',
        text: '公告',
        cursor: false,
        maximum: 20,
        num: 0,
        place: ['indexPage', 'pagesCustom', 'categoryPage', 'personalCenter', 'goodsDetail']
    },
    {
        name: 'kefu',
        icon: 'headset-one',
        text: '在线客服',
        cursor: false,
        maximum: 1,
        num: 0,
        place: ['indexPage', 'pagesCustom', 'categoryPage', 'goodsDetail']
    },
    {
        name: 'zhangfen',
        icon: 'people-plus-one',
        text: '涨粉',
        cursor: false,
        maximum: 20,
        num: 0,
        developing: true, // 开发中
        place: []
    },
    {
        name: 'videoTmp',
        icon: 'shipin',
        text: '视频',
        cursor: false,
        maximum: 50,
        num: 0,
        place: ['indexPage', 'pagesCustom', 'categoryPage', 'personalCenter', 'goodsDetail']
    },
    {
        name: 'fengefuzhu',
        icon: 'fengefuzhu2',
        text: '分割辅助',
        cursor: false,
        maximum: 50,
        num: 0,
        place: ['indexPage', 'pagesCustom', 'categoryPage', 'personalCenter', 'goodsDetail']
    },
    {
        name: 'zichan',
        icon: 'financing-one',
        text: '会员资产',
        cursor: false,
        maximum: 1,
        num: 0,
        place: ['indexPage', 'pagesCustom', 'categoryPage', 'goodsDetail']
    },
    {
        name: 'tanchuang',
        icon: 'fengefuzhu2',
        text: '悬浮',
        cursor: false,
        maximum: 10,
        num: 0,
        place: ['indexPage', 'pagesCustom', 'categoryPage', 'personalCenter', 'goodsDetail']
    },
]